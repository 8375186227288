import { SelectionChangedEvent } from "ag-grid-community";
import { GroupEntity } from "domain/entity/Group/GroupEntity";
import { INITIAL_GROUP_COL_DEF } from "presentation/constant/Group/GroupMaintenanceColumnDefinition";
import { GroupMaintenanceConstant } from "presentation/constant/Group/GroupMaintenanceConstant";
import { useGroupMaintenanceVM } from "presentation/hook/Group/useGroupMaintenanceVM";
import { useGroupMaintenanceTracked } from "presentation/store/Group/GroupMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";
const GroupTablePanel: React.FC = () => {
    const [groupState] = useGroupMaintenanceTracked();
    const groupVM = useGroupMaintenanceVM();
    const gridRef: any = useRef(null);
    const { tableData } = groupState;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        groupVM.updateSelectedRows(tableData, selectedRows);
    }, [groupVM, tableData])
    const handleAddClick = useCallback(() => {
        groupVM.onAdd();
    }, [groupVM])
    const handleRowDoubleClick = useCallback((entity: GroupEntity) => {
        groupVM.onEdit(entity);
    }, [groupVM])
    const memoTable = useMemo(() => {
        return (
            <HPHTable
                id='group-maintenance-table'
                headerLabel={GroupMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_GROUP_COL_DEF}
                data={tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={true}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: GroupEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    }, [tableData, handleAddClick, handleSelectionChange, handleRowDoubleClick])
    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}
export default memo(GroupTablePanel); 
